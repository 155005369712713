import "core-js/modules/es.array.push.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, unref as _unref, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-154dbe64"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "count-page"
};
const _hoisted_2 = {
  class: "ws_table"
};
const _hoisted_3 = {
  class: "btn ws_mb20"
};
import { onMounted, ref, watch, onUnmounted } from 'vue';
import DistributorForm from './components/distributor-form.vue';
import moment from 'moment';
import OperationButtonList from 'common/components/operation-button-list';
import { queryDistributorPage, deleteDistributorForm } from '@/api';
import { CODE_ENUM } from 'common';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const advancedRef = ref();
    const tableData = ref([]);
    const formConf = ref({
      visible: false,
      data: {}
    });
    const handleForm = (data = {}) => {
      formConf.value = {
        visible: true,
        data
      };
    };
    const toDetail = ({
      id
    }) => {
      router.push({
        path: '/distribution/distributor-list/distributor-detail',
        query: {
          id
        }
      });
    };
    const handleDelete = row => {
      ElMessageBox.confirm('你确定要删除这个分销商吗?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        autofocus: false
      }).then(() => {
        toDelete(row);
      });
    };
    const toDelete = async ({
      id
    }) => {
      const res = await deleteDistributorForm({
        id
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        ElMessage.success('删除成功');
        fetchList();
      }
    };
    const buttonList = [{
      text: '详情',
      onClick: toDetail
    }, {
      text: '编辑',
      onClick: handleForm
    }, {
      text: '删除',
      onClick: handleDelete
    }];
    const formInline = ref({
      productKey: ''
    });
    const pageParams = ref({
      curPage: 1,
      pageSize: 10
    });
    const total = ref(0);
    const handleSizeChange = () => {
      pageParams.value.curPage = 1;
      fetchList();
    };
    const handleCurrentChange = () => {
      fetchList();
    };
    const col = [{
      prop: 'distributorName',
      label: '分销商名称'
    }, {
      prop: 'distributorLevel',
      label: '等级'
    }, {
      prop: 'distributorRatio',
      label: '分润比例',
      formatter: ({
        distributorRatio
      }) => {
        return distributorRatio + '%';
      }
    }, {
      prop: 'contactPerson',
      label: '联系人'
    }, {
      prop: 'contactPhone',
      label: '手机'
    }, {
      prop: 'contactAddress',
      label: '地址'
    }, {
      prop: 'createDate',
      label: '创建时间',
      formatter: ({
        createDate
      }) => {
        return moment(createDate).format('YYYY-MM-DD HH:mm:ss');
      }
    }];
    const tableLoading = ref(false);
    const fetchList = async () => {
      tableLoading.value = true;
      const res = await queryDistributorPage({
        ...pageParams.value
      }).finally(() => {
        tableLoading.value = false;
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        var _res$data, _res$data2;
        tableData.value = (res === null || res === void 0 || (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.records) || [];
        total.value = (res === null || res === void 0 || (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.total) || 0;
      }
    };
    fetchList();
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_empty = _resolveComponent("el-empty");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: handleForm
      }, {
        default: _withCtx(() => [_createTextVNode("添加")]),
        _: 1
      })]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "table",
        data: tableData.value
      }, {
        empty: _withCtx(() => [_createVNode(_component_el_empty, {
          description: "暂无数据"
        })]),
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(col, (item, index) => {
          return _createVNode(_component_el_table_column, _mergeProps({
            key: index,
            ref_for: true
          }, {
            ...item
          }, {
            "show-overflow-tooltip": ""
          }), null, 16);
        }), 64)), _createVNode(_component_el_table_column, {
          label: "操作",
          width: "240"
        }, {
          default: _withCtx(scope => [_createVNode(_unref(OperationButtonList), {
            buttonList: buttonList,
            scope: scope.row
          }, null, 8, ["scope"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, tableLoading.value]]), _createVNode(_component_el_pagination, {
        class: "ws_mt50",
        "current-page": pageParams.value.curPage,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => pageParams.value.curPage = $event),
        "page-size": pageParams.value.pageSize,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => pageParams.value.pageSize = $event),
        "page-sizes": _ctx.PAGE_SIZES,
        layout: "->, total, sizes, prev, pager, next, jumper ",
        total: total.value,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "page-sizes", "total"])]), _createVNode(DistributorForm, {
        visible: formConf.value.visible,
        "onUpdate:visible": _cache[2] || (_cache[2] = $event => formConf.value.visible = $event),
        data: formConf.value.data,
        onSuccess: fetchList
      }, null, 8, ["visible", "data"])]);
    };
  }
};