import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-59f740ce"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "button-list-wrapper"
};
export default {
  __name: 'index',
  props: ['buttonList', 'scope'],
  setup(__props) {
    const props = __props;
    const buttonClick = click => {
      click && click(props.scope);
    };
    const buttonDisabled = disabled => {
      if (typeof disabled === 'function') {
        return disabled();
      }
      return disabled;
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.buttonList, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createVNode(_component_el_button, {
          disabled: buttonDisabled(item.disabled),
          class: "ws_ml4 ws_mr4",
          onClick: $event => buttonClick(item.onClick),
          type: "",
          text: ""
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.text), 1)]),
          _: 2
        }, 1032, ["disabled", "onClick"]), index !== props.buttonList.length - 1 ? (_openBlock(), _createBlock(_component_el_divider, {
          key: 0,
          direction: "vertical"
        })) : _createCommentVNode("", true)]);
      }), 128))]);
    };
  }
};