import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-68240c1a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "common-upload-wrapper"
};
const _hoisted_2 = {
  key: 0,
  class: "preview-box ws_mr15"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "overlay"
};
const _hoisted_5 = {
  class: "preview-dialog-box"
};
const _hoisted_6 = ["src"];
import { ref, watch, computed } from 'vue';
import { CODE_ENUM, SUFFIX } from 'common';
import { ElMessage } from 'element-plus';
import { Delete, View, Plus } from '@element-plus/icons-vue';
export default {
  __name: 'index',
  props: ['modelValue', 'defaultImg', 'url'],
  emits: ['update:modelValue'],
  setup(__props, {
    emit: __emit
  }) {
    const classes = computed(() => {
      return realProductImage.value ? '' : 'avatar-uploader';
    });
    const uploadRef = ref();
    const $emit = __emit;
    const props = __props;
    const {
      VUE_APP_BASE_URL
    } = process.env;
    const uploadAction = computed(() => {
      const url = typeof props.url === 'function' ? props.url() : props.url;
      return VUE_APP_BASE_URL + url;
    });
    const uploadHeaders = {
      Authorization: 'Bearer ' + localStorage.getItem('WX_Authorization')
    };
    const realProductImage = ref('');
    watch(() => props.modelValue, () => {
      if (!props.modelValue) {
        realProductImage.value = '';
      }
    }, {
      deep: true
    });
    watch(() => props.defaultImg, () => {
      realProductImage.value = props.defaultImg;
    }, {
      deep: true,
      immediate: true
    });
    const handleUploadSuccess = res => {
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        const {
          baseUrl,
          path
        } = res.data || {};
        realProductImage.value = baseUrl + path;
        $emit('update:modelValue', path);
      } else {
        ElMessage.error((res === null || res === void 0 ? void 0 : res.msg) || '上传失败，请重新上传');
      }
    };
    const handleUploadRemove = () => {
      realProductImage.value = '';
    };
    const handleUploadError = response => {
      ElMessage.error('上传失败，请重新上传');
    };
    const remove = () => {
      realProductImage.value = '';
      $emit('update:modelValue', '');
    };
    const dialogVisible = ref(false);
    const preview = () => {
      dialogVisible.value = true;
    };
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [realProductImage.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("img", {
        src: realProductImage.value,
        alt: ""
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_icon, {
        onClick: preview,
        size: "16px",
        color: "#fff",
        class: "icon"
      }, {
        default: _withCtx(() => [_createVNode(_unref(View))]),
        _: 1
      }), _createVNode(_component_el_icon, {
        onClick: remove,
        size: "16px",
        color: "#fff",
        class: "icon"
      }, {
        default: _withCtx(() => [_createVNode(_unref(Delete))]),
        _: 1
      })])])) : _createCommentVNode("", true), _createVNode(_component_el_upload, {
        ref_key: "uploadRef",
        ref: uploadRef,
        headers: uploadHeaders,
        class: _normalizeClass(classes.value),
        action: uploadAction.value,
        "show-file-list": false,
        "on-success": handleUploadSuccess,
        "on-error": handleUploadError,
        "on-remove": handleUploadRemove
      }, {
        default: _withCtx(() => [!realProductImage.value ? (_openBlock(), _createBlock(_component_el_icon, {
          key: 0,
          class: "avatar-uploader-icon"
        }, {
          default: _withCtx(() => [_createVNode(_unref(Plus))]),
          _: 1
        })) : (_openBlock(), _createBlock(_component_el_button, {
          key: 1
        }, {
          default: _withCtx(() => [_createTextVNode("更换照片")]),
          _: 1
        }))]),
        _: 1
      }, 8, ["class", "action"])]), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        title: "图片预览"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
          "w-full": "",
          class: "preview-img",
          src: realProductImage.value
        }, null, 8, _hoisted_6)])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};